.track {
  &::before {
    background-color: var(--mantine-color-beige-1);
  }
}

.track:before {
  background-color: var(--mantine-color-beige-1);
}

.input {
  border-color: var(--mantine-color-dark-1);
  color: var(--mantine-color-dark-1);
  background-color: var(--mantine-color-beige-0);
}
