.container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: var(--mantine-color-beige-0);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  margin: 0 auto;
  @media (min-width: 1000px) {
    display: none;
  }
}
